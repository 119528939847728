import getSiteTypeData from "../../../core/getSiteTypeData";
import { HOLIDAY_WEBSITE_CONTENT_WIDTH } from "../../constants";
import { getResource, selectSite } from "../../selectors";
import CommentCardSection from "./CommentCardSection";
import NewCommentForm from "./NewCommentForm";
import { css } from "@emotion/react";
import { Button as FancyButton, TinyHeadline } from "@minted/fancyclothes";
import { theme, GlobalStyles, styleUtils } from "@minted/minted-components";
import PropTypes from "prop-types";
import { React, useMemo, useState, useCallback } from "react";
import { connect } from "react-redux";

const propTypes = {
  comments: PropTypes.arrayOf(PropTypes.object),
  isReadOnly: PropTypes.bool,
  site: PropTypes.object.isRequired,
};

const centeredContainerStyles = css`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const commentButtonContainerStyles = css`
  margin-bottom: ${theme.spacing.x6};
  max-width: ${HOLIDAY_WEBSITE_CONTENT_WIDTH};
  width: 100%;
`;

const commentButtonContainerStylesOnlineInvitation = css`
  width: 100%;
  max-width: ${styleUtils.rem(500)};
`;

const commentSectionStyles = css`
  padding-left: ${theme.spacing.x6};
  padding-right: ${theme.spacing.x6};
`;

const countCommentsStyles = css`
  margin-bottom: ${theme.spacing.x2};
  overflow-wrap: break-word;
  text-align: center;
`;

export const countComments = (comments) =>
  comments.reduce(
    (sum, comment) => sum + 1 + (comment.replies?.length || 0),
    0,
  );

export const CommentSection = ({ comments, isReadOnly, site }) => {
  const [isWritingComment, setIsWritingComment] = useState(false);
  const [replyId, setReplyId] = useState(undefined);

  const closeDrawer = useCallback(() => {
    setIsWritingComment(false);
  }, []);
  const { isOnlineInvitationSite } = getSiteTypeData({
    siteType: site.siteType,
  });

  const startParentComment = useCallback(() => {
    setIsWritingComment(true);
    setReplyId(undefined);
  }, []);
  const startReplyComment = useCallback(
    (replyId) => () => {
      setIsWritingComment(true);
      setReplyId(replyId);
    },
    [],
  );

  const countOfComments = useMemo(() => countComments(comments), [comments]);

  const commentText = useMemo(() => {
    if (countOfComments > 0) {
      return `${countOfComments} comment${countOfComments === 1 ? "" : "s"}`;
    }

    return "Be the first one to write a comment";
  }, [countOfComments]);

  return (
    <div css={commentSectionStyles} id="comment_section">
      <GlobalStyles />
      <div css={centeredContainerStyles}>
        <div css={countCommentsStyles}>
          <TinyHeadline>{commentText}</TinyHeadline>
        </div>
        <div
          css={
            isOnlineInvitationSite
              ? commentButtonContainerStylesOnlineInvitation
              : commentButtonContainerStyles
          }
        >
          <FancyButton
            block={true}
            disabled={isReadOnly}
            onClick={startParentComment}
            text="Write Comment"
          />
        </div>
        <NewCommentForm
          closeDrawer={closeDrawer}
          isWritingComment={isWritingComment}
          replyId={replyId}
        />
        <CommentCardSection
          comments={comments}
          isDeleteEnabled={site.isTenant && site.status !== "archived"}
          isReadOnly={isReadOnly}
          startReplyComment={startReplyComment}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const comments = getResource(state.resources.comments);
  const site = selectSite(state.resources.sites);

  return {
    comments,
    site,
  };
};

CommentSection.propTypes = propTypes;

export default connect(mapStateToProps)(CommentSection);
